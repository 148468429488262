.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--Backgroundsection);
  color: var(--High-contrast-Text);
}

.parallax-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroller {
  white-space: nowrap;
  display: flex;
  font-size: 7rem;
  font-weight: bold;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 0.1rem var(--High-contrast-Text);
}

.scroller span {
  margin-right: 30px;
}

.absolute {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--Backgroundsection);
  z-index: 9999;
}

.AccueilContainer,
.BoutiqueContainer {
  display: block;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  min-width: 100vw;
}
.wrap {
  position: relative;
  width: calc(100vw - 15vw);
  height: auto;
  left: 15vw;
  overflow: hidden;
  padding: 0;
}

.inner-wrap {
  position: relative;
  width: 100%;
  height: auto;
}

.presentation {
  position: relative;
  width: 30vw;
  left: 7.5vw;
  padding-top: 16vw;
}

.presentation p {
  margin-top: 20px;
  text-align: justify;
}

.GrosTitre {
  position: relative;
  margin-top: 1vw;
  z-index: 4;
  font-size: 9rem;
  left: 18vw;
  top: 53px;
}

.outline {
  -webkit-text-stroke: 0.2rem var(--High-contrast-Text);
  color: transparent;
  letter-spacing: -0.2rem;
}

.inner-home-container {
  margin-top: 8vh;
}

.inner-home {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 50vw;
  overflow: hidden;
  z-index: 5;
  margin-top: 0;
}

.reverse {
  flex-direction: row-reverse;
}

.inner-home-info,
.inner-home-info-reverse {
  width: 40%;
  height: 100%;
  max-height: 650px !important;
  top: 0;
  position: relative;
  display: block;
  padding-top: 10vw;
  padding-left: 5vw;
  padding-right: 5vw;
}

.inner-home-info {
  margin-left: auto;
}
.inner-home-info-reverse {
  margin-right: auto;
}

.inner-home-info p,
.inner-home-info-reverse p {
  text-align: left;
}

.inner-home-image-reverse,
.inner-home-image {
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  max-height: 650px !important;
  z-index: 3;
  overflow: hidden;
}
.HomeItem {
  display: inline-block;
  position: relative;
}

.HomeItem-photo {
  display: block;
  max-width: 100%;
  height: auto;
}

.inner-home-image-reverse a,
.inner-home-image a {
  display: flex;
  width: 100%;
  height: 100%;
}
.inner-home-image-reverse a {
  justify-content: flex-start;
}

.inner-home-image a {
  justify-content: flex-end;
}

.inner-home-image img,
.inner-home-image-reverse img {
  position: relative;
  height: 100%;
  width: auto;
}

.block-event-inner {
  background-color: rgb(0, 0, 0);
  position: relative;
  letter-spacing: -0.2rem;
  z-index: 4;
  width: 85vw;
  font-size: calc(3vh + 3vw);
  padding-left: 10.5vw;
}

video {
  height: 100%;
  width: auto;
}

.bottom-video {
  position: relative;
  bottom: 100vh;
}

.ContainerBandeaux {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 90vh;
  margin-top: 8vh;
  margin-bottom: 8vh;
}

.Bandeaux {
  height: 100%;
  width: 19%;
  overflow: hidden;
  position: relative;
}

.Bandeaux-petit {
  position: relative;
  width: 19%;
  height: 70%;
  overflow: hidden;
}

.Bandeaux video {
  position: relative;
  right: 500px;
}

.Bandeaux-petit video {
  position: relative;
  right: 500px;
}

.Bandeaux video,
.Bandeaux-petit video {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.Bandeaux video:hover,
.Bandeaux-petit video:hover {
  filter: none;
  transition: filter 0.3s ease;
}

.content-text {
  white-space: pre-line;
}

.NewsTitle {
  color: var(--disabledLink);
}

@media (max-width: 768px) {
  .wrap {
    width: 100%;
    left: 0;
    overflow: visible;
    padding-top: 10vh;
  }

  .presentation {
    position: relative;
    width: 70%;
    left: 35px;
  }

  .GrosTitre {
    margin-top: 15vw;
    letter-spacing: -0.4rem;
    font-size: 18vw;
    position: relative;
    margin-top: 1vw;
    left: -4vw;
    top: 25px;
  }

  .inner-home-image img,
  .inner-home-image-reverse img {
    position: relative;
    height: auto;
    width: 100%;
  }

  .presentation h2 {
    font: bold normal 1.9rem/2.5rem;
  }

  .presentation p {
    font: normal normal 1.7rem/2rem;
    margin-top: 20px;
  }

  .inner-home {
    position: relative;
    width: 100%;
    height: auto;
    left: 0;
    margin-top: 5vh;
  }

  .inner-home-info,
  .inner-home-info-reverse {
    order: 2;
    height: auto;
    width: 100%;
    left: 0;
    padding-right: 35px;
    padding-left: 35px;
    min-height: 10rem;
    padding-top: 10vw;
  }

  .inner-home-image-reverse,
  .inner-home-image {
    order: -1;
    overflow: hidden;
    position: relative;
    top: 0;
    width: 100%;
  }

  .block-event-inner {
    position: relative;
    top: unset;
    width: 100vw;
    left: -3.5%;
    font-size: 1.8rem;
    padding: 2rem 0 2rem 0;
  }

  .outline {
    -webkit-text-stroke: 0.03rem #ffffff;
    width: 100%;
    padding-right: 0;
  }

  .ContainerForme {
    height: 50vh;
    width: 100%;

    padding: 1%;
  }

  .ContainerFormes {
    position: relative;
    max-height: 30vh;
    width: 100vw;
  }

  .bottom-video {
    position: relative;
    bottom: 50vh;
  }

  .Bandeaux-petit {
    width: 23%;
  }

  .Bandeaux {
    width: 23%;
  }

  .ContainerBandeaux {
    margin-top: 0;
    height: 70vh;
  }

  .gauche {
    top: -15px;
  }

  .milleu-gauche {
    top: 40px;
  }

  .droite {
    top: 15px;
  }
}
/* résolution d'écran 4k 2560*1080  */
@media (min-width: 2559px) {
  .inner-home {
    height: 90vh;
  }

  .inner-home-image,
  .inner-home-image-reverse {
    max-height: 1000px !important;
  }
}
