@font-face {
  font-family: "good_timesregular";
  src: url("styles/Font/good_times_rg-webfont.woff2") format("woff2"),
    url("styles/Font/good_times_rg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "good_timesregular";
  background-color: var(--Backgroundsection);
  color: var(--High-contrast-Text);
}

body.modal-open,
html.modal-open {
  overflow: hidden;
}

:root {
  /* font sizes */
  --font-size-large: 2rem;
  --font-size-medium: 1.5rem;
  --font-size-small: clamp(12px, 0.8vw, 16px);

  --spacing-small: 10px;
  --spacing-medium: 20px;
  --border-radius: 20px;

  --Backgroundsection: #111111;
  --BordersAndSeparators: #484848;
  --High-contrast-Text: #eeeeee;
  --links: #b4b4b4;
  --disabledLink: #7b7b7b;
  --titre: #eeeeee;
}

.dark-mode {
  --primary-color: #111111;
  --secondary-color: #111111;
  --font-color: #eeeeee;
  --bg-color: #111111;
  --heading-color: #111111;
}

.light-mode {
  --primary-color: #eeeeee;
  --secondary-color: #eeeeee;
  --font-color: #111111;
  --bg-color: #eeeeee;
  --heading-color: #eeeeee;
}

[data-theme="dark"] {
  --Backgroundsection: #111111;
  --BordersAndSeparators: #484848;
  --High-contrast-Text: #eeeeee;
  --links: #b4b4b4;
  --disabledLink: #7b7b7b;
  --titre: #eeeeee;
}

[data-theme="light"] {
  --Backgroundsection: #eeeeee;
  --BordersAndSeparators: #eeeeee;
  --High-contrast-Text: #111111;
  --links: #111111;
  --disabledLink: #111111;
  --titre: #111111;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-display: swap;
}

.html {
  display: block;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}

.curseur {
  background-color: var(--High-contrast-Text);
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  box-shadow: 1px 1px 2px black, 0 0 1em rgb(0, 0, 0);
  mix-blend-mode: difference;
}

.curseur-text {
  text-shadow: 1px 1px 2px black, 0 0 1em rgb(0, 0, 0);
  color: black;
}

a {
  text-decoration: none;
  color: var(--High-contrast-Text);
  list-style-type: none;
}

a:hover {
  color: var(--links);
  transform: all 0.3s;
}

header {
  position: fixed;
  width: auto;
  left: 15vw;
  z-index: 1000;
  opacity: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.06em;
  line-height: 1em;
  font-weight: 500;
}

h1 {
  color: var(--titre);
  font-size: clamp(25px, calc(40 * 0.01 * 8vmin), 30px);
  text-align: center;
}

h2,
h3 {
  color: var(--High-contrast-Text);
  font-size: clamp(18px, calc(25 * 0.01 * 8vmin), 30px);
  text-align: left;
  width: 100%;
}

h4 {
  color: var(--titre);
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

h5 {
  color: var(--titre);
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

h6 {
  color: var(--titre);
  font-size: 1rem;
  text-align: center;
}

main {
  width: 100%;
  height: 100%;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 90vw;
}

input {
  height: 100%;
  background-color: transparent;
  color: var(--High-contrast-Text);
  border: 1px solid var(--High-contrast-Text);
  padding: 8px;
  border-radius: 10px;
  width: 80%;
  margin: 10px;
}

footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  transition: all 0.3s ease-out;
  width: calc(100% - 15vw);
  overflow: hidden;
  min-height: 25vh;
  justify-content: center;
  align-items: center;
  margin-left: 15vw;
}

button {
  color: var(--High-contrast-Text);
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  border: none;
  outline: none;

  z-index: 1;
}

label {
  width: auto;
  height: 100%;
}

svg {
  height: 100%;
}

p {
  word-wrap: break-word;
  white-space: normal;
  text-align: justify;
  padding-bottom: 1em;
  font-size: var(--font-size-small);
  line-height: 1.3;
}

textarea {
  width: 100%;
  background-color: transparent;
  border-radius: 15px;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  border: 1px solid var(--High-contrast-Text);
  color: var(--High-contrast-Text);
}

select {
  width: 100%;
  background-color: transparent;
  border-radius: 15px;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  color: var(--High-contrast-Text);
  border: 1px solid var(--High-contrast-Text);
}

select option {
  font-size: 1rem;
  color: var(--BordersAndSeparators);
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: var(--BordersAndSeparators);
  box-shadow: 0 0 5px var(--BordersAndSeparators);
}

form {
  width: 100%;
  height: 100%;
  text-align: center;
}

iframe {
  width: 100%;
  height: 500px;
}

::selection {
  background: var(--Backgroundsection);
  color: var(--High-contrast-Text);
  text-shadow: none;
  outline: none !important;
}

@media (max-width: 768px) {
  section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-height: none;
    max-width: 100vw;
  }

  h1 {
    margin-bottom: 0;
  }

  h2,
  h3 {
    color: var(--High-contrast-Text);
    width: 100%;
  }

  p {
    font-size: 0.6rem;
  }

  iframe {
    height: 200px;
  }

  footer {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 1030px) {
  h4 {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 2559px) {
  h6 {
    color: #eee;
    color: var(--titre);
    font-size: 1.2rem;
    text-align: center;
  }

  select,
  textarea {
    background-color: initial;
    border: 1px solid #eee;
    border: 1px solid var(--High-contrast-Text);
    border-radius: 2px;
    color: #eee;
    color: var(--High-contrast-Text);
    margin: 2vh 0;
    padding: 22px;
    width: 100%;
    height: 4vh;
    font-size: 1.7rem;
  }

  h1 {
    color: var(--titre);
    font-size: clamp(25px, calc(40 * 0.01 * 12vmin), 50px);
    text-align: center;
  }

  h2,
  h3 {
    color: var(--High-contrast-Text);
    font-size: clamp(18px, calc(25 * 0.01 * 12vmin), 30px);
    text-align: left;
    width: 100%;
  }

  p {
    word-wrap: break-word;
    white-space: normal;
    text-align: justify;
    padding-bottom: 0.5em;
    line-height: 1.3;
  }
}
