.title-slide {
  font-size: 3rem;
  margin: 10px 0px 0px 0px;
  letter-spacing: -0.1rem;
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
  .title-slide {
    font-size: 1.5rem;
    margin: 5px 0px 0px 0px;
  }
}
