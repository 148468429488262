/* Styles de la popup principale */
.WelcomeModal {
  width: 80%;
  max-width: 600px;
  height: auto;
  position: fixed;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 10000;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modalBackdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  z-index: 9000;
}

/* Styles pour le titre, texte, lien et bouton */
.WelcomeModal h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
}

.WelcomeModal p {
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.85);
}

.WelcomeModal a {
  color: rgba(200, 200, 200, 0.8);
  text-decoration: underline;
  transition: color 0.3s ease;
}
.WelcomeModal a:hover {
  color: rgba(255, 255, 255, 1);
}

.WelcomeModal button {
  margin-top: 15px;
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: transparent;
  color: white;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.WelcomeModal button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 1);
}
