.Evenements {
    padding: 2vw;
    margin-top: 12vh;
}


.EvenementItem-photo {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
}

.Evenement-ALU .EvenementItem {
    height: 30vh;
}

.Evenement-ALU .EvenementItem .EvenementContent {
    padding: 15px;

}

.Evenement-ALU .EvenementItem .EvenementContent h2 {
    margin-bottom: 7%;
    text-align: center;
    font-size: clamp(18px, calc(32* 0.01* 10vmin), 30px);
}

.Evenement-PC .EvenementItem .EvenementContent h2 {
    margin-bottom: 15px;
    text-align: left;
    font-size: clamp(12px, calc(15* 0.01* 10vmin), 20px);
}

.Evenement-PS .EvenementItem .EvenementContent h2 {
    margin-bottom: 15px;
    text-align: left;
    font-size: clamp(12px, calc(15* 0.01* 10vmin), 20px);
}


.Evenement-ALU .EvenementItem .EvenementContent p {
    margin-left: 7%;
    font-size: 1vw;
    padding: 0.2em;
}

.EvenementContent p {
    padding: 0.1em;
}


.EvenementItem {
    position: relative;
    background-size: cover;
    background-position: center;
    color: var(--High-contrast-Text);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.Evenement-ALU,
.Evenement-PS,
.Evenement-PC {
    width: 100%;
    height: 100%;
    padding: 2vw;
}

.EvenementContent {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}

.Container-Cartes-Evenement {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.Container-Cartes-Evenement .EvenementItem {
    flex: 1 1 calc(33.333% - 20px);
    margin: 10px;
    height: 15vh;
}

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}



/* Flèche carousel  */

.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    background-color: #eeeeeec6;
    border-radius: 50%;
    padding: 1.2vw;
    width: 3vw;
    height: 3vw;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s, fill 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-next {
    right: 10px;

}

.custom-prev {
    left: 10px;
}

.custom-arrow svg {
    fill: #000000;
    transition: fill 0.3s;
    z-index: 2;
}

.custom-next:hover {
    background-color: #eeeeee;
    box-shadow: 3px 0px 2px rgba(255, 255, 255, 0.5);
    transform: scale(1.08) translateY(-50%);
    transition: all 0.3s;
}

.custom-prev:hover {
    background-color: #eeeeee;
    box-shadow: -3px 0px 2px rgba(255, 255, 255, 0.5);
    transform: scale(1.08) translateY(-50%);
    transition: all 0.3s;
}


.custom-arrow:hover svg {
    fill: #000;
}


.three-dots {
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer;
    font-size: 13px;
    color: var(--High-contrast-Text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.three-dots span {
    display: block;
    line-height: 0;
}

.dot2 {
    position: absolute;
    top: 16px;
}

.dot3 {
    position: absolute;
    top: 8px;
}

/* Fenêtre modale */
.Evenement-Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000c;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.Evenement-Modal-content {
    background-color: #111;
    padding: 2vw;
    border-radius: var(--border-radius);
    border: 1px solid var(--High-contrast-Text);
    width: 85%;
    height: 95%;
    max-height: 95%;
    overflow-y: auto;
    position: fixed;
    background: linear-gradient(to bottom, #000000, #111111);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    scrollbar-width: thin;

}

.Evenement-Modal-content::-webkit-scrollbar {
    width: 8px;
}

.Evenement-Modal-content::-webkit-scrollbar-thumb {
    background-color: var(--disabledLinks);
    border-radius: var(--border-radius);
}

.Evenement-Modal-content::-webkit-scrollbar-track {
    background: #111;
}


.Infos-Modal {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.Evenement-Modal-content h2 {
    font-size: clamp(18px, calc(32* 0.01* 10vmin), 30px);
    margin-bottom: 25px;
    text-align: center;
}

.Evenement-Modal-content p,
h2 {
    padding: 0;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.photos img {
    width: 100%;
    margin: 10px 0;
    border-radius: var(--border-radius);
}

.photos {
    max-height: 70vh;
    height: 100%;
    overflow-y: auto;
    padding: 1vw;
}

.ImagesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    grid-gap: 10px;

}

.Evenement-date {
    color: #6E6E6E;
}

.lazy-image {
    object-fit: cover;
    border-radius: var(--border-radius);
    width: 100%;
    height: auto;
}

.custom-arrow svg {
    width: 0.8vw;
    height: 0.8vw;
}

.custom-arrow svg {
    width: 0.8vw;
    height: 0.8vw;
}

.Evenement-Modal-infos {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 10px;
}

.Evenement-Modal-infos p{
    font-size: calc(0.5rem + 0.4vw); /* Ajustement de la taille de police en fonction du viewport */
    line-height: 1.5; /* Légère hauteur de ligne pour la lisibilité */
    
    

}


@media screen and (min-width: 2560px) {

    .custom-arrow svg {
        width: 1.8vw;
        height: 1.8vw;
    }

    .custom-arrow svg {
        width: 1.8vw;
        height: 1.8vw;
    }

    .Evenement-ALU .EvenementItem .EvenementContent h2 {
        margin-bottom: 10vh;
        text-align: center;
        font-size: clamp(18px, calc(32* 0.01* 10vmin), 30px);
    }


    .Evenement-ALU .EvenementItem {
        height: 50vh;
    }

    .Evenement-Modal-content {
        padding: 0;
    }


    .Container-Cartes-Evenement .EvenementItem {
        flex-basis: calc(33.333% - 20px);
        flex-grow: 1;
        flex-shrink: 1;
        height: 25vh;
        margin: 10px;
    }

    .Evenement-PC .EvenementItem .EvenementContent h2,
    .Evenement-PS .EvenementItem .EvenementContent h2 {
        font-size: clamp(12px, 2.5vmin, 25px);
        margin-bottom: 5vh;
        text-align: left;
    }

    .three-dots {
        align-items: center;
        color: var(--High-contrast-Text);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-size: 35px;
        justify-content: center;
        position: absolute;
        right: 15px;
        top: 12px;
    }

    .dot3 {
        top: 16px;
    }

    .dot2 {
        top: 32px;
    }
}

@media screen and (max-width: 1200px) {

    .event {
        padding: 5px;

    }

    .lieu,
    .date,
    .info {
        padding: 5px;
        text-align: center;
    }

    .lieu p {
        text-align: center;
    }

    .event-action {
        display: flex;
        justify-content: flex-end;
    }

    .btn {
        font-size: 0.7rem;
        display: block;
        margin-bottom: 10px;
    }

    .block-event-inner-page {
        padding-left: 0;
    }
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {
    .Evenements {
        padding: 2vw;
        margin-top: 0vh;
    }

    .Container-Cartes-Evenement .EvenementItem {
        flex: 1 1 calc(100% - 20px);
        margin: 5px;
        height: 15vh;
    }

    .ImagesContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        grid-gap: 10px;
    }
}

@media screen and (max-width: 520px) {}