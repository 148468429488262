header {
  opacity: 0;
  animation: headerAnimation 0.2s ease-in-out forwards;
  animation-delay: 0.1s;
  animation-duration: 1s;
  width: auto;
  height: auto;
  left: calc(15vw - 50px);
}

@keyframes headerAnimation {
  0% {
    top: -100%;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

.ContainerHeader,
.LOGO-Anime,
.PULSAR__name h1,
.PULSAR__container,
.PULSAR {
  display: flex;
}

.ContainerHeader {
  flex-direction: row;
  gap: 10px;
}

.LOGO-Anime {
  flex-direction: row;
  height: 100%;
  position: relative;
  fill: #111;
  z-index: 1000;
}

.logo {
  width: 150px;
  height: auto;
  margin-top: 20px;
}

.LOG input {
  margin: 8px 10px;
  padding: 8px;
  border-radius: var(--border-radius);
  border: none;
  background-color: var(--Backgroundsection);
  outline: none;
}

.Pulsar-inc {
  height: 100%;
  width: 100%;
}

.PULSAR__name h1 {
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  letter-spacing: -0.1rem;
}

.pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 15px;
  height: 15px;
  background: var(--High-contrast-Text);
  border-radius: 50%;
  animation: pulse 3s ease-in-out forwards;
  animation-delay: 2s;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.pulse span {
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  opacity: 0.8;
  animation: pulseAnimate 4s ease-out infinite;
  animation-delay: calc(1s * var(--i));
}

@keyframes pulseAnimate {
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.PULSAR__container {
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  max-width: 100px;
}

.PULSAR__container svg {
  width: 100%;
  height: 100%;
}

.PULSAR__logo {
  display: block;
  width: auto;
  max-height: 200px;
  transform-origin: center;
  animation-delay: 0.25s;
  animation-duration: 7s;
}

.PULSAR {
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  height: 100%;
  background: transparent;
}

@media screen and (min-width: 2559px) {
  header {
    -webkit-animation: headerAnimation 0.2s ease-in-out forwards;
    animation: headerAnimation 0.2s ease-in-out forwards;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    height: auto;
    left: calc(15vw - 75px);
    opacity: 0;
    width: auto;
  }

  .PULSAR__container {
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    max-width: 180px;
  }

  .PULSAR__name h1 {
    font-size: clamp(18px, 5.2vmin, 25px);
  }

  .pulse {
    -webkit-animation: pulse 3s ease-in-out forwards;
    animation: pulse 3s ease-in-out forwards;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    background: var(--High-contrast-Text);
    border-radius: 50%;
    height: 25px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    width: 25px;
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1200px) {
  .PULSAR__logo {
    width: 80px;
  }
}

@media screen and (max-width: 768px) {
  header {
    left: 0;
    width: 100vw;
    opacity: 0;
    animation: headerAnimation 0.2s ease-in-out forwards;
    animation-delay: 0.1s;
    animation-duration: 1s;
    height: auto;
    background-color: var(--Backgroundsection);
  }

  .ContainerHeader {
    display: flex;
    flex-direction: row;
    gap: 0px;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    margin-left: 10px;
  }

  .navbar {
    padding: 2vw;
    z-index: 1;
  }

  .LOGO-Anime {
    right: 0px;
    top: 0px;
    width: auto;
    min-height: 55px;
    position: relative;
  }
  .PULSAR__name h1 {
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    letter-spacing: -0.1rem;
    font-size: clamp(18px, calc(40 * 0.01 * 8vmin), 20px);
  }

  .PULSAR__container svg {
    width: 100%;
    height: 100%;
    max-height: 60px;
  }
  .pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 8px;
    height: 8px;
    background: var(--High-contrast-Text);
    border-radius: 50%;
    animation: pulse 3s ease-in-out forwards;
    animation-delay: 2s;
  }
}

@media screen and (max-width: 576px) {
}
