.Btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.Btn-box a {
  margin-top: 20px;
}

.Btn-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
}

.Btn-box a:hover {
  background: var(--High-contrast-Text);
  color: var(--Backgroundsection);
  border-radius: 5px;
  box-shadow: 0 0 5px var(--High-contrast-Text),
    0 0 4px var(--High-contrast-Text), 0 0 10px var(--High-contrast-Text),
    0 0 18px var(--High-contrast-Text);
}

.Btn-box a span {
  position: absolute;
  display: block;
}

.Btn-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--High-contrast-Text));
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.Btn-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--High-contrast-Text));
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.Btn-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, var(--High-contrast-Text));
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.Btn-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, var(--High-contrast-Text));
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

@media screen and (max-width: 576px) {
  .Btn-box form a {
    font-size: 0.8rem;
    margin-top: 0px;
    letter-spacing: 4px;
  }
}

.event-details-maps-link-button {
  display: flex;
  background: transparent;
  border: 3px solid var(--High-contrast-Text);
  border-radius: 15px;
  color: var(--High-contrast-Text);
  padding: 10px 10px;
  cursor: pointer;
  font-family: "good_timesregular";
  font-size: 0.8rem;
  margin-bottom: 2vh;
  box-shadow: none;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.event-details-maps-link-button p {
  padding: 0;
}

.event-details-maps-link-button:hover {
  border: 3px solid var(--links);
  color: var(--links);
}

.event-details-maps-link-button:hover::after {
  width: 0;
}

.event-details-maps-link-button a:hover {
  color: var(--links);
}

.Dimention,
.DimentionEventFutur,
.DimentionEventPasse {
  font-family: "good_timesregular";
  border: none;
  background: none;
  cursor: pointer;
  perspective: 2000px;
  box-shadow: none;
  font-size: calc(3vh + 3vw);
  overflow: hidden;
}

.DimentionEventFutur,
.DimentionEventPasse {
  font-size: 4rem;
}

.Dimention:hover::after,
.DimentionEventFutur:hover::after,
.DimentionEventPasse:hover::after {
  width: 0%;
  box-shadow: none;
}

.Dimention span,
.DimentionEventFutur span,
.DimentionEventPasse span {
  display: block;
  background-color: transparent;
  font-weight: 600;
  color: var(--High-contrast-Text);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: top;
  transition: transform 0.3s ease-out;
}

.Dimention span::after {
  content: "éVéNEMENTS";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 100%;
  transform-origin: top;
  background-color: transparent;
  transform: rotateX(-90deg);
  color: var(--High-contrast-Text);
}

.Dimention:hover span,
.DimentionEventFutur:hover span,
.DimentionEventPasse:hover span {
  transform: rotateX(88deg) translateY(-50%);
}

/* evenement */
.DimentionEventFutur span::after {
  content: "futurs";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 100%;
  transform-origin: top;
  background-color: transparent;
  transform: rotateX(-88.5deg);
  color: var(--High-contrast-Text);
}

.DimentionEventPasse span::after {
  content: "passés";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 100%;
  transform-origin: top;
  background-color: transparent;
  transform: rotateX(-88.5deg);
  color: var(--High-contrast-Text);
}

/* TEST JEREM */
.Dimention2 {
  font-family: "good_timesregular";
  border: none;
  background: none;
  cursor: pointer;
  perspective: 20000px;
  box-shadow: none;
  font-size: 6rem;
  overflow: hidden;
}

.Dimention2:hover::after {
  width: 0%;
  box-shadow: none;
}

.Dimention2 span {
  display: block;
  padding: 10px 6px;
  background-color: transparent;
  color: var(--High-contrast-Text);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: right;
  transition: transform 0.3s ease-out;
}

.Dimention2 span::after {
  content: "PULSAR";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 100%;
  transform-origin: right;
  background-color: transparent;
  transform: rotateY(90deg);
  color: var(--High-contrast-Text);
}

.Dimention2:hover span {
  transform: rotateY(-90deg) translateX(-50%);
}

@media (max-width: 768px) {
  .Dimention {
    font-size: calc(3vh + 3vw);
  }

  .Dimention span::after {
    transform: rotateX(-90deg);
  }

  .Dimention:hover span {
    transform: rotateX(90deg) translateY(-50%);
  }
}
