.ContainerInfosEvenement {
  margin-top: 12vh;
  width: 100%;
}

.ContainerEmplacement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 75vh;
  width: 100%;
  padding: 0% 3% 0% 3%;
}

.TroisEmplacement,
.DeuxEmplacment,
.UnEmplacement {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20vh;
  max-height: 192px;
  min-height: 192px;
  justify-content: space-between;
  align-items: center;
}

.PhotoEmplacement1,
.PhotoEmplacement2,
.DétailsEvénéments,
.Emplacement2 {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.586);
  border-radius: 25px;
  box-shadow: inset 0 0 25px black;
  overflow: hidden;
}

.PhotoEmplacement1 img {
  height: auto;
  width: 120%;
}

.PhotoEmplacement2 img {
  width: 100%;
  height: auto;
  position: relative;
  bottom: 100px;
}

.Emplacement2 img {
  width: 100%;
  height: auto;
  position: relative;
  bottom: 18px;
}

.PhotoEmplacement3 a {
  width: 100%;
  height: 100%;
}

.PhotoEmplacement3 svg {
  width: 80%;
  height: 80%;
}

.PhotoEmplacement3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
  max-height: 192px;
  min-height: 192px;
}

.UnEmplacement {
  width: 100%;
  border-radius: 25px;
  box-shadow: inset 0 0 25px black;
}

.PhotoEmplacement1,
.PhotoEmplacement3 {
  width: 20%;
}

.PhotoEmplacement2 {
  width: 50%;
}

.DétailsEvénéments {
  width: 55%;
}

.Emplacement2 {
  width: 40%;
}

.DétailsEvénéments {
  padding: 20px;
}

.glassmorphic {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
}

.event-details-title {
  width: 100%;
  border-bottom: 3px solid var(--High-contrast-Text);
  padding: 10px;
  text-align: center;
}

.event-details-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 12vh;
}

.event-details-artist,
.event-details-location,
.event-details-maps-link {
  font-size: 0.9rem;
  width: 100%;
  color: #6e6e6e;
}

.event-details-artist-data,
.event-details-location-data,
.event-details-maps-link-data {
  display: flex;
  width: 100%;
  border-top: 3px solid var(--High-contrast-Text);
  border-bottom: 3px solid var(--High-contrast-Text);
  color: var(--High-contrast-Text);
  transition: transform 0.2s ease;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  padding: 1em;
}

.event-details-photos-title {
  text-align: center;
}

.event-details-date {
  text-align: center;
  font-size: 0.9rem;
  width: 100%;
  color: #6e6e6e;
}

.event-details-date-data {
  display: flex;
  text-align: center;
  width: 100%;
  border-bottom: 3px solid var(--High-contrast-Text);
  color: var(--High-contrast-Text);
  transition: transform 0.2s ease;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  padding: 10px;
}

.event-details-photos ul {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.event-details-photos li {
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 2%;
  justify-content: center;
  align-items: center;
}

.event-details-photos img {
  object-fit: contain;
  width: 100%;
  height: auto;
  max-width: 1200px;
  border-radius: 10px;
}

.event-details-maps-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
