.CGV-container{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    margin-bottom: auto;
    flex-direction: column;
    margin-top: 12vh;
    width: 100%;
}



@media (max-width: 768px) {
.CGV-container{
    margin: 0;
}
    
    }