.Charnière {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 15vw;
  height: 100%;
  background-color: rgb(0, 0, 0);
  position: fixed;

  /* Stries */
  background-image: repeating-linear-gradient(
    90deg,
    rgb(0, 0, 0),
    /* Couleur de fond de base */ rgb(0, 0, 0) 9px,
    /* Fin de la première couleur de fond */ var(--BordersAndSeparators) 10px,
    /* Début de la couleur de la rainure */ var(--BordersAndSeparators) 13px
      /* Fin de la rainure et répétition */
  );
  background-size: 10px 100%; /* Taille de la bande totale (rainure + espace) */
}

.CharnièreTexte {
  height: 100vh;
  font-size: calc(4vw + 4vh);
  text-align: center;
  writing-mode: vertical-lr;
  white-space: nowrap;
  position: relative;
  padding: 0;
  margin: 0 auto;
  line-height: 1;
}
@media (max-width: 768px) {
  .Charnière {
    display: none;
  }
}
