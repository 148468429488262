.audio-container {
  position: fixed;
  top: 2vh;
  right: 2vh;
  z-index: 100;
}

.lyrics-container {
  overflow-y: auto;
  max-height: 100vh;
}

.lyric-line {
  font-size: 2rem;
  margin-bottom: 10px;
  white-space: pre-wrap; /* Garder les espaces */
  color: #888888; /* Couleur des paroles non activées */
  transition: color 0.3s ease;
}

.lyric-line.highlighted {
  position: relative;
  color: white; /* Couleur pour la phrase en cours */
  background: none; /* Pas d'effet de bloc */
  -webkit-mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-mask-position: left;
  mask-position: left;
  animation: text-fill 3s linear forwards;
}
@keyframes text-fill {
  0% {
    -webkit-mask-position: left;
    mask-position: left;
  }
  100% {
    -webkit-mask-position: right;
    mask-position: right;
  }
}

.lyric-line.highlighted::before {
  content: attr(data-lyric);
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to right, white 0%, white 100%);
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  z-index: -1;
}

.lyric-line.highlighted span {
  position: relative;
  background: linear-gradient(to right, #ffffff 0%, transparent 100%);

  -webkit-text-fill-color: transparent;
  animation: fill-text 2s forwards linear;
}

@keyframes fill-text {
  0% {
    background-size: 0% 100%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@keyframes text-glow {
  0% {
    background-size: 0% 100%;
  }
  100% {
    background-size: 100% 100%;
  }
}
