.NotreEquipe {
  min-height: 35vh;
}

.tracklist {
  list-style: none;
  padding: 0;
  width: 100%;
  padding: 20px;
}

.track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.track-title {
  font-size: var(--font-size-small);
  flex: 1;
  text-align: left;
}

.track-artist {
  font-size: var(--font-size-small);
  flex: 1;
  text-align: right;
}

.track-separator {
  flex: 1;
  height: 1px;
  background-color: white;
  margin: 0 10px;
}

.member-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  max-width: 30vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.member-details img {
  width: auto;
  height: 100%;
  max-height: 250px;
  object-fit: cover;
  margin-bottom: 10px;
  filter: grayscale(100%) drop-shadow(2px 0px 3px white);
}

.member-details p {
  font-size: 0.55rem;
  text-align: left;
}

.member-details-infos {
  width: 100%;
  padding: 0em 0em 0em 1em;
}

.Vslide4-container_track {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* hummmmmm c'est la merde */

@media screen and (min-width: 2559px) {
  .tracklist {
    list-style: none;
    padding: 20px;
    width: 100%;
    max-width: 1700px;
  }
}

@media screen and (max-width: 1200px) {
  .track-title {
    font-size: 11px;
  }

  .track-artist {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .tracklist {
    margin-left: 0;
    margin-right: 0;
  }

  .member-details {
    max-width: 100%;
  }
}

@media screen and (max-width: 576px) {
}
