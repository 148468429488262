.v-slide5 {
  margin-top: 12vh;
  width: 100%;
}

.Contact {
  display: flex;
  align-items: center;
  align-content: center;
  height: calc(100vh - 200px);
  margin-top: 0;
  max-width: 90vw;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
}

.ContainerColonne50 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 1180px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.Colonne50 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 12px;
  width: 100%;
}

.Colonne50 input {
  width: 50%;
}

.Colonne50 img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 2vh;
}

.Colonne50 h4 {
  text-align: center;
  width: 100%;
  display: block;
}

.Colonne50 h2 {
  text-align: center;
  width: 100%;
  display: block;
  font-size: clamp(18px, calc(25 * 0.01 * 8vmin), 30px);
}

.Contact-box {
  align-items: flex-start;
  border-radius: var(--border-radius);
  box-shadow: inset 1px 1px 1px 0 var(--High-contrast-Text),
    3px 3px 10px 0 var(--High-contrast-Text),
    2px 2px 3.5px 0 var(--High-contrast-Text);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2vh;
  margin-top: 2vh;
  max-width: 1100px;
  padding: 20px;
  width: 80%;
}

.ColonneCentre,
.Horizontale {
  display: flex;
  height: 100%;
  justify-content: center;
}

.ColonneCentre {
  align-items: center;
  flex-direction: column;
  font-size: var(--font-size-small);
  gap: 10px;
  padding: 5px;
  text-align: center;
  width: 100%;
}
.ColonneCentre p {
  white-space: nowrap;
}

.Contact-box .user-box {
  position: relative;
}

.Contact-box .user-box input {
  width: 100%;
  padding: 10px;
  font-size: var(--font-size-small);
  color: var(--High-contrast-Text);
  margin-bottom: 30px;
  border-bottom: 1px solid var(--High-contrast-Text);
  outline: none;
  background: transparent;
}

.Contact-box .user-box textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  font-size: var(--font-size-small);
  color: var(--High-contrast-Text);
  margin-top: 20px;
  border: none;
  border: 1px solid var(--High-contrast-Text);
  outline: none;
  background: transparent;
  resize: none;
}

.Contact-box .user-box label {
  font-size: var(--font-size-small);
  color: var(--High-contrast-Text);
  pointer-events: none;
}

.Contact-box p {
  text-align: left;
  color: var(--High-contrast-Text);
  text-align: right;
  font-size: var(--font-size-small);
  text-align: center;
}

.Btnform {
  position: relative;
  display: inline-block;
  color: var(--High-contrast-Text);
  background-color: rgba(0, 0, 0, 0);
  font-size: var(--font-size-small);
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 4px;
}

.Btnform input:hover {
  background: var(--High-contrast-Text);
  color: #000000;
  border-radius: 5px;
  box-shadow: 0 0 5px var(--High-contrast-Text),
    0 0 25px var(--High-contrast-Text), 0 0 50px var(--High-contrast-Text),
    0 0 100px var(--High-contrast-Text);
}

.Btnform span {
  position: absolute;
  display: block;
}

.Btnform span:nth-child(2) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--High-contrast-Text));
  animation: btn-anim1 1s linear infinite;
}

.NotreEquipe {
  width: 100%;
  max-width: 100%;
  margin-top: 4vh;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.Btnform span:nth-child(3) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--High-contrast-Text));
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.Btnform span:nth-child(4) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, var(--High-contrast-Text));
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.Btnform span:nth-child(5) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, var(--High-contrast-Text));
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

.Btnform input {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: var(--High-contrast-Text);
  background-color: rgba(0, 0, 0, 0);
  font-size: var(--font-size-small);
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 4px;
  font-family: "good_timesregular";
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

.Made {
  display: flex;
  font-size: var(--font-size-small);
  color: var(--High-contrast-Text);
  margin-top: 100px;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
}

.Description {
  padding-top: 1em;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .Contact-box h2 {
    margin-bottom: 10px;
    font-size: var(--font-size-small);
  }

  .Contact-box .user-box input {
    font-size: var(--font-size-small);
  }

  .Contact-box .user-box textarea {
    font-size: var(--font-size-small);

    height: 20vh;
  }

  .Contact-box .user-box label {
    font-size: var(--font-size-small);
  }

  .Made {
    margin-top: 40px;
    margin-bottom: 150px;
  }
}

@media (max-width: 768px) {
  .Contact-box {
    width: 80%;
    padding: 20px;
  }

  .Contact {
    height: 100vh;
    margin-top: 10vh;
  }

  .Made {
    margin-top: 40px;
  }

  .Horizontale {
    flex-direction: column;
  }

  .Colonne50 {
    padding: 0;
  }

  .Contact-box {
    flex-direction: column;
    width: 90%;
  }

  .ContainerColonne50 {
    flex-direction: column;
    padding: 2em;
  }

  .v-slide5 {
    margin-top: 0;
  }

  .Colonne50 img {
    width: 100%;
    max-width: 200px;
    margin-bottom: 2vh;
  }

  .member-details img {
    width: auto;
    height: 100%;
    max-height: 140px;
    object-fit: cover;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .Contact {
    height: 100vh;
    margin-top: 10vh;
  }

  .Made {
    margin-top: 40px;
  }
}

.Notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--High-contrast-Text);
  padding: 10px;
  border-radius: var(--border-radius);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}
