.Boutique {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  height: 100vh;
  margin-top: 100px;
  max-width: 100vw;
  width: 100vw;
  flex-direction: column;
  overflow: auto;
}

.cart-icon-container {
  display: inline-block;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #ffd700, 0 0 20px #ffd700, 0 0 30px #ffd700,
      0 0 40px #ffd700;
  }
  to {
    text-shadow: 0 0 20px #ffd700, 0 0 30px #ffd700, 0 0 40px #ffd700,
      0 0 50px #ffd700;
  }
}

video:-webkit-full-screen {
  display: none;
}

.Boutique img {
  width: 500px;
}

.MeilleurVente {
  height: 85vh;
}

.MeilleurVente-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: space-around;
}

.Carte-Produits-Items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 12vw;
  gap: 0px;
  border-radius: var(--border-radius);
  height: auto;
  margin-bottom: 20px;
}

.Carte-Produits-Items-bas {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 17vh;
  width: 100%;
  justify-content: space-evenly;
}

.Carte-Produits-Items-bas a {
  margin-bottom: 5px;
}
.Carte-Produits-Items-bas p {
  margin-bottom: 5px;
  padding: 0;
}

.Carte-Produits-Items-haut {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 180px;
}

.Carte-Produits-Items-haut-img {
  height: auto;
  width: 100%;

  object-fit: cover;
  border-radius: 50%;
}

.Carte-Produits-Items-haut-img-supperpose {
  position: absolute;
  height: auto;
  width: 101%;
  object-fit: cover;
  border-radius: 50%;
}

.Carte-Produits-Items-haut-img-supperpose {
  opacity: 0;
}

.Carte-Produits-Items-haut:hover .Carte-Produits-Items-haut-img-supperpose {
  opacity: 1;
  transition: all 0.8s;
}
.Panier {
  width: 98%;
  height: 100%;
  padding: 12px;
  font-size: 1.3rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 500;
  position: relative;
  color: var(--High-contrast-Text);
  z-index: 0;
  border: 1px solid var(--High-contrast-Text);
  border-radius: var(--border-radius);
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 50px;
}
.Panier::before {
  content: "";
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, #866095, #560968);
  border-radius: 50% 50% 0% 0%;
  z-index: -1;
  transition: all 0.5s;
}
.Panier:hover::before {
  top: 0px;
  border-radius: 0;
}

.Carte-Produits-Items-haut:hover .Carte-Produits-Items-haut-img {
  opacity: 0;
  transition: all 1s ease-out;
}

.boutique {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 80% 40%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 70% 30%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 60% 20%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 50% 10%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 40% 0%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 0% 40%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 10% 50%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 20% 60%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 30% 70%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 40% 80%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 50% 90%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at 60% 100%, rgb(73, 0, 73), transparent 1%),
    radial-gradient(circle at center, #866095, #560968);
  border-radius: 50%;
  animation: pulse 3s ease-in-out forwards;
  animation-delay: 0.35s;
  z-index: -1;
}

.inner-home-boutique {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  overflow: hidden;
  z-index: 5;
  margin-top: 7.5vh;
  margin-bottom: 7vh;
}

.inner-home-image-boutique {
  overflow: hidden;
  position: relative;
  top: 0;
  left: 4.5vw;
  width: 57%;
  height: 100vh;
  max-height: 500px !important;
  z-index: 3;
}

.GrosTitre-boutique {
  position: relative;
  margin-top: -7vw;
  z-index: 4;
  font-size: calc(5vh + 5vw);
  top: 53px;
}
.GrosTitre-boutique span {
  font-size: calc(5vh + 5vw);
}

.GrosTitre-boutique h1 {
  position: relative;
  font-size: 0.9rem;
  right: 117px;
  top: 2vh;
}

.GrosTitre-boutique-reverse h1 {
  position: relative;
  font-size: 0.9rem;
  left: 265px;
  top: -2vh;
}

.GrosTitre-boutique-reverse {
  position: relative;
  margin-top: 7vw;
  z-index: 4;
  font-size: 9rem;
  right: 3vw;
  top: 53px;
}

.block-event-inner-boutique {
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  height: 200px;
  max-height: 200px;
  letter-spacing: -0.2rem;
  z-index: 4;
  width: 100%;
  font-size: 6rem;
  background-image: url("/public/images/Boutique/Shooting\ Mitchy\ retouche\ 03\ 02-137.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.inner-home-reverse-image-boutique {
  overflow: hidden;
  position: relative;
  bottom: 24vh;
  right: 0vw;
  width: 100%;
  height: 100vh;
  max-height: 500px !important;
  z-index: 3;
}

@media (min-width: 2559px) {
  .inner-home-image-boutique video {
    height: auto;
    width: 100%;
    position: absolute;
    top: -100px;
  }

  .inner-home-image-boutique {
    height: 100vh;
    left: 7.5vw;
    max-height: 500px !important;
    overflow: hidden;
    position: relative;
    top: 0;
    width: 60%;
    z-index: 3;
  }

  .GrosTitre-boutique h1 {
    font-size: 1.2rem;
    position: relative;
    right: 117px;
    top: 2vh;
  }
  .Carte-Produits-Items {
    border-radius: var(--border-radius);
    gap: 0;
    height: auto;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 15vw;
  }

  .Panier {
    width: 98%;
    height: 100%;
    padding: 12px;
    font-size: 2.2rem;
    font-family: "Courier New", Courier, monospace;
    font-weight: 500;
    position: relative;
    color: var(--High-contrast-Text);
    z-index: 0;
    border: 1px solid var(--High-contrast-Text);
    border-radius: var(--border-radius);
    background: rgba(0, 0, 0, 0);
    overflow: hidden;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 50px;
  }
}

@media (max-width: 768px) {
  .MeilleurVente-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;
  }

  .Carte-Produits-Items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 100%;
    min-height: 50vh;
  }

  .MeilleurVente {
    height: auto;
  }

  .Carte-Produits-Items-haut {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
    max-height: 350px;
  }

  .Carte-Produits-Items-bas {
    display: flex;
    height: 100%;
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }

  .Carte-Produits-Items-haut-img-supperpose {
    position: absolute;
    height: 100%;
    width: auto;
    object-fit: cover;
    border-radius: 50%;
  }

  .Carte-Produits-Items-haut-img {
    height: 100%;
    width: auto;
    border-radius: 50%;
    object-fit: cover;
    max-width: 275px;
  }

  .GrosTitre-boutique {
    position: relative;
    margin-top: -7vw;
    z-index: 4;
    font-size: 4rem;
    left: -5vw;
    top: -40px;
  }

  .inner-home-boutique {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    overflow: hidden;
    z-index: 5;
    margin-top: 0;
  }

  .inner-home-image-boutique,
  .inner-home-reverse-image-boutique {
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 20vh;
    max-height: 500px !important;
    z-index: 3;
  }

  .boutique {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: pulse 3s ease-in-out forwards;
    animation-delay: 2s;
    z-index: -1;
  }

  .boutique span {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    opacity: 0.8;
    animation: pulseAnimate 4s ease-out infinite;
    animation-delay: calc(1s * var(--i));
  }

  .GrosTitre-boutique-reverse {
    position: relative;
    margin-top: 7vw;
    z-index: 4;
    font-size: 4rem;
    left: -10vw;
    top: 36px;
  }

  .GrosTitre-boutique h1 {
    position: relative;
    font-size: 0.9rem;
    right: 105px;
    top: 1vh;
  }

  .GrosTitre-boutique-reverse h1 {
    position: relative;
    font-size: 0.9rem;
    left: 90px;
    top: 0vh;
  }
}
