.center {
    max-width: 95%;
}

.produit-supplementaire-image-container img {
    width: 100%;
    height: auto;
    padding: 7px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.slick-list {
    margin: 0 auto;
    display: inline-block;
}

/* isdpf,d */
.slick-thumb {
    bottom: -45px;
}

.slick-dots {
    margin-left: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots-test li {
    width: 70px;
    height: 45px;
    display: inline-block;
    margin: 0 5px;
    padding: 0;

}



.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
}


/* ------ */


.slide {
    height: auto;
}

.slick-slider {
    width: 100%;
}

.carrousel-container {
    max-height: 180px;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.SlideFooter {
    max-height: 180px;
}

.SlideFooter img {
    max-height: 180px;
    width: auto;
}


/* FicheProduit */


.produit-supplementaire-image {
    max-height: 80vh;
    border-radius: 20px;
}


/* Vous l'aimez  */

.produit-image-utilisateur {
    max-height: 360px;
    width: auto;
}


/* Carrousel produit */
.slick-thumb li img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    border-radius: 5px;
    width: 100%;
}

.slick-thumb li.slick-active img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

@media (max-width: 768px) {

    .slick-slider {
        width: 100%;
    }

    .SlideFooter img {
        max-height: 120px;
        width: auto;
    }

    /* fiche produit */

    .produit-supplementaire-image {
        max-height: auto;
        width: 100%;
    }


}