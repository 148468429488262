.Cart {
  position: fixed;
  right: 5vw;
  top: 1.6vh;
  padding: 15px;
  border-radius: 100%;
  /* background-color:  var(--Backgroundsection); */
  cursor: pointer;
  z-index: 9002;
}

.BackdropModalCart {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.275);
  z-index: 9000;
}

.ModalCart {
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  padding: 2px;
  background-color: var(--Backgroundsection);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9001;
  border: 1px solid rgb(255, 255, 255);
  justify-content: space-evenly;
  padding-top: 11vh;
}

.cart-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-height: 150px;
  padding: 5px;
  border: 1px solid white;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cart-item-image {
  width: auto;
  overflow: hidden;
  border-radius: var(--border-radius);
  padding: 5px;
}

.cart-item-image img {
  width: auto;
  height: 100%;
}

.cart-icon-number {
  position: relative;
  top: -10px;
  right: -15px;
}

.cart-icon-number p {
  text-align: right;
}

.Container-Carte-Items {
  display: flex;
  height: 100%;
  width: 101%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 70vh;
  /* Hauteur maximale du conteneur, ajustez selon vos besoins */
  overflow-y: auto;
  /* Permet le défilement vertical si le contenu dépasse la hauteur maximale */
  padding: 10px;
  /* Ajoute un peu d'espace à l'intérieur du conteneur */
  border: 1px solid #ddd;
}

.RemoveItem {
  color: rgb(219, 1, 1);
  padding: 0;
}

.CalculPrixTotal {
  height: 2vh;
}

.Cart svg {
  width: 35px;
}

.item-details {
  padding: 5px;
  width: 50%;
}

.cart-item-images {
  width: 100%;
  height: 100%;
}

.Titre h6 {
  text-align: left;
  margin-bottom: 5px;
  font-size: 0.8rem;
}

.Titre p {
  padding: 0;
}

.left-part {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.right-part {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  justify-content: space-between;
}

.Selector {
  text-align: right;
}

.Selector span {
  padding: 10px;
}

.Price {
  text-align: right;
}

.modal-backdrop {
  position: fixed;

  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 1000000;
}

.ModalCart h2 {
  text-align: center;
}

.ModalCartInter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ModalCartInter h2 {
  width: 100%;
  text-align: center;
}

/* chat */

.ItemsIterPanier {
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;
}

/* Section de l'image */
.ItemsIterPanier img {
  width: 30%;
  height: auto;
  border-radius: 4px;
}

/* Section des détails du produit */
.product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 30%;
}

/* Section de la quantité et du prix */
.quantity-price {
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.quantity-price p {
  margin: 4px 0;
}

/* Boutons pour la quantité */
.quantity-buttons {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.quantity-buttons button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
}

.ChoixQuantité {
  display: flex;
  flex-direction: row;
}

/* Bouton de suppression */
.SuprimmerArticle {
  right: 1.5vw;
  top: 1.5vw;
  background-color: transparent;
  box-shadow: none;
}

.ModalCart ul {
  width: 100%;
}

@media screen and (min-width: 2559px) {
  .Cart svg {
    width: 70px;
  }

  .Cart {
    border-radius: 100%;
    cursor: pointer;
    padding: 0px;
    position: fixed;
    right: 2vw;
    top: 5vh;
    z-index: 9001;
  }
}

@media (max-width: 768px) {
  .ModalCart {
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    padding: 0;
    background-color: var(--Backgroundsection);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9001;
    border: 1px solid rgb(255, 255, 255);
    padding-top: 10vh;
  }

  .Cart {
    position: fixed;
    right: 5vw;
    max-height: 60px;
    top: 0;
    margin-top: 20px;
    margin-right: 10px;
    padding: 0;
    border-radius: 100%;
    cursor: pointer;
    z-index: 9002;
  }

  .Cart svg {
    width: 25px;
  }
}
