.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  z-index: 99999;
}

.scroll-to-top-button svg {
  width: 100%;
  height: 100%;
}

.scroll-to-top-button.visible {
  display: flex;
}

@media screen and (min-width: 2559px) {

.scroll-to-top-button {
  position: fixed;
  bottom: 30px;
  right: 20px;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  z-index: 99999;
}
}