.artist-page {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  margin-bottom: auto;
  max-width: 100vw;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  padding: 0;
}

.artist-bio,
.artist-discography,
.artist-clips-performances,
.artist-contact {
  padding-bottom: 3vw;
  max-width: 1200px;
}

.Atriste-p {
  text-align: justify;
  padding: 0;
  width: 100%;
  padding: 0.8em 0em 0.2em 0em;
}

.logo-artiste {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.logo-artiste img {
  max-height: 100px;
}

.album #Annee,
.modal-content #Annee {
  color: #6e6e6e;
  padding: 0;
}

.artist-header h1 {
  text-align: center;
  margin-bottom: 20px;
  -webkit-text-stroke: 0.05rem var(--High-contrast-Text);
  color: transparent;
  font-size: 3rem;
  margin: 10px 0px 0px 0px;
  letter-spacing: -0.1rem;
}

.artist-header img {
  position: relative;
  z-index: -100;
  top: -12px;
  width: auto;
  max-height: 50vh;
  max-width: 1700px;
}

.ArtisteTitreSection {
  border-bottom: 3px solid var(--titre);
  color: var(--titre);
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.artist-bio img {
  max-width: 1000px;
  width: 100%;
  border-radius: var(--border-radius);
}

.artist-discography {
  width: 100%;
}

.album {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: fit-content;
  cursor: pointer;
}

.album img {
  max-width: 15vw;
  min-width: 15vw;
  border-radius: var(--border-radius);
}

.artist-clips-performances {
  width: 100%;
}

.artist-clips-performances-liste {
  display: flex;
  gap: 20px;
  width: 100%;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.artist-news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.artist-news svg {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  text-decoration: none;
  color: var(--High-contrast-Text);
}

.artist-contact {
  width: 100%;
}

.ArtisteReseaux {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: nowrap;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.Reseaux {
  font-size: 12px;
  margin-top: 10px;
  text-decoration: none;
}

.ArtisteReseaux a {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.ArtisteReseaux a:hover {
  color: var(--links);
}

.ArtisteReseaux svg {
  width: 5vw;
  height: auto;
  text-decoration: none;
}

.Prochainement {
  filter: blur(5px);
}

.album-discography {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding: 2em;
  align-items: center;
  gap: 2em;
  min-height: 15vh;
}

/* Styles pour la modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.Atriste-p-container p {
  text-align: center;
}

.modal-content {
  position: fixed;
  background-color: #111111;
  padding: 2.5vw;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--High-contrast-Text);
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 1002;
}

.modal-content img {
  max-width: 150px;
  height: auto;
  border-radius: var(--border-radius);
}

.Modal-titre {
  margin-top: 25px;
}

.Fermer-modal {
  position: absolute;
  right: 1.5vw;
  top: 1.5vw;
  background-color: transparent;
  box-shadow: none;
}

.Fermer-modal:after {
  background-color: transparent;
  box-shadow: none;
}

.Fermer-modal span::before,
.Fermer-modal span::after {
  display: block;
  width: 40px;
  height: 1px;
  position: relative;
  border-radius: 3px;
  background-color: var(--High-contrast-Text);
}

.Fermer-modal span::before {
  content: "";
  position: absolute;
  left: 0;
  transform: rotate(45deg);
}

.Fermer-modal span::after {
  content: "";
  position: absolute;
  left: 0;
  transform: rotate(-45deg);
}

.Modal-plateform {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
}

.track-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.track-list p {
  display: flex;
  justify-content: flex-end;
}

.block-event-inner-artiste {
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  height: 300px;
  z-index: 4;
  width: 100%;
  font-size: 6rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.outline-artiste {
  -webkit-text-stroke: 0.2rem var(--High-contrast-Text);
  color: transparent;
  letter-spacing: -0.2rem;
}

.presentation-artiste {
  position: relative;
  width: 30vw;
  left: 7.5vw;
  padding-top: 14vw;
}

.ContainerForme-artiste {
  height: 100vh;
  width: 100%;
}

.GrosTitre-artiste {
  position: relative;
  z-index: 4;
  font-size: 6rem;
  left: 3vw;
}

.inner-wrap-artiste {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 12vh;
}

.inner-wrap-artiste img {
  width: 30%;
}

/* container video */
.ContainerFormesArtistes {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 90vh;
  width: 100%;
  margin-top: 2vh;
  position: relative;
}

.FormeArtistes {
  width: 30%;
  height: 100%;
  overflow: hidden;
}

.FormeArtistesG {
  position: relative;
  right: 400px;
}

.FormeArtistesM {
  position: relative;
  /* right: 400px; */
}

.FormeArtistesD {
  position: relative;
  /* right: 400px; */
}

@media screen and (max-width: 1200px) {
  .ArtisteReseaux svg,
  .artist-news svg {
    width: 100%;
  }

  .ArtisteTitreSection {
    margin-bottom: 20px;
  }

  .Reseaux {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .ArtisteReseaux {
    margin-top: 0;
    width: 110%;
  }

  .ArtisteReseaux svg,
  .artist-news svg {
    width: 40px;
  }

  .ArtisteTitreSection {
    margin-bottom: 20px;
  }

  .ArtisteReseaux p {
    display: none;
  }

  .album img {
    max-width: 50vw;
    border-radius: var(--border-radius);
  }

  .block-event-inner-artiste {
    width: 133%;
  }

  .GrosTitre-artiste {
    letter-spacing: -0.4rem;
    font-size: 18vw;
    position: relative;
    left: -3.2vw;
    bottom: -150px;
  }

  .presentation-artiste {
    width: 70vw;
  }

  .presentation-artiste h2 {
    font-size: 0.7rem;
  }

  .track-list p {
    font-size: 0.6rem;
  }

  .modal-content {
    padding: 2.5vmax;
    width: 96%;
  }

  .Fermer-modal {
    right: 2.5vmax;
    top: 2.5vmax;
  }

  .inner-wrap-artiste {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 2vh;
  }

  .ContainerFormesArtistes {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 70vh;
    width: 100%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    position: relative;
  }

  .block-event-inner-artiste {
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    height: 200px;
    background-position: 100% 35%;
    z-index: 4;
    width: 100%;
    font-size: 6rem;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .album-discography {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 2em;
    align-items: center;
    gap: 2em;
    margin-top: 4vh;
  }
}

@media screen and (max-width: 576px) {
  .ArtisteTitreSection {
    margin-bottom: 20px;
    width: 100%;
  }

  .Reseaux {
    display: none;
  }
}

@media screen and (min-width: 2559px) {
  .FormeArtistesG {
    position: relative;
    right: 400px;
  }

  .FormeArtistesG,
  .FormeArtistesD,
  .FormeArtistesG {
    width: auto;
  }

  .Reseaux {
    font-size: 20px;
    margin-top: 10px;
    text-decoration: none;
  }

  .FormeArtistesM,
  .FormeArtistesD {
    width: 100%;
    height: auto;
  }

  .artist-contact {
    max-width: 1700px;
    padding-bottom: 3vw;
  }

  .block-event-inner-artiste {
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    height: 400px;
    z-index: 4;
    width: 100%;
    font-size: 6rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 52%;
  }
  .album-discography {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 4em;
    align-items: center;
    gap: 2em;
    min-height: 15vh;
  }

  .modal-content {
    position: fixed;
    background-color: #111111;
    padding: 1vw;
    border-radius: var(--border-radius);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 30vw;
    width: 100%;
    text-align: center;
    max-height: 95vh;
    z-index: 1002;
  }
  .Modal-plateform {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    gap: 40px;
  }

  .track-list p {
    min-width: 30px;
    font-size: 0.8vw;
    display: flex;
    justify-content: flex-end;
  }
}
